import * as React from "react";
import "./contact.css";
import { StaticImage } from "gatsby-plugin-image";
const Contact = () => {
  return (
    <div className="contact_container">
      <div className="contact_container_inner">
        <div className="contact_img">
          <StaticImage
            alt="my second image"
            src="../../images/full_me.jpg"
            // width={300}
            // height={300}
          />
        </div>
        <div className="contact_text">
          <div className="contact_title">Contact Me</div>
          <div className="contact_details">
            <p>
              Email:{" "}
              <span style={{ color: "#64ffda" }}> ameenessid@gmail.com </span>
            </p>
            <p>
              Whatsapp: <span style={{ color: "#64ffda" }}> +21641078038 </span>
            </p>
            {/* <p>
              I am also working as a teacher for
              <span style={{ color: "#64ffda" }}>
                {" "}
                Tunisian ministry of education
              </span>
              , and that is my third year teaching kids and I've been enjoying
              every single moment working with these fascinating human being.
            </p> */}
            <div className="contact_icons_container">
              <div>
                <a
                  href="https://github.com/Amin-Essid"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <StaticImage
                    src="../../images/github.png"
                    alt="github icon"
                    width={93.3}
                    height={100}
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/in/mohamed-amine-essid-868335143/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <StaticImage
                    src="../../images/linkedin.png"
                    alt="linkedin icon"
                    width={100}
                    height={100}
                  />
                </a>
              </div>
              <div>
                <a
                  href="mailto:ameenessid@gmail.com"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <StaticImage
                    src="../../images/email.png"
                    alt="email icon"
                    width={99.25}
                    height={100}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Contact;
