import * as React from "react";
import "./featuredProject.css";
import Project from "../project/project";
const FeaturedProject = ({ project, imagesUrls }) => {
  return (
    <div className="featuredProject_container">
      <div className="featuredProject_maintitle">Some Of My Work</div>
      <Project project={project} imagesUrls={imagesUrls} />
    </div>
  );
};

export default FeaturedProject;
