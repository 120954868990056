import * as React from "react";
import "./header.css";
import { StaticImage } from "gatsby-plugin-image";
const Header = () => {
  return (
    <div className="header_container">
      <div className="header_container_inner">
        <div className="header_text">
          <div className="header_info_intro">Hi, my name is</div>
          <div className="header_info_title">Ameen Essid.</div>
          <div className="header_info_description">
            Top-Rated Freelancer on Upwork and FIverr
          </div>
          <div className="header_info_details">
            <p>
              I'm a software developer specialized in building modern web
              applications for clients and businesses from all around the world.
            </p>
          </div>
          <a
            href="mailto:ameenessid@gmail.com"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div className="header_info_btn">contact me!</div>
          </a>
        </div>
        <div className="header_img">
          <StaticImage alt="my first image" src="../../images/real_me.png" />
        </div>
      </div>
    </div>
  );
};

export default Header;
