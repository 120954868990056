import * as React from "react";
import Hamburger from "hamburger-react";
import { useState } from "react";
import "./mobileNav.css";
import { StaticImage } from "gatsby-plugin-image";

const MobileNav = ({ setCurrentIndex, sliderRef, currentIndex }) => {
  const [menuToggle, setMenuToggle] = useState(false);

  const menu = (
    <div className="menu_icon">
      <Hamburger
        toggled={menuToggle}
        toggle={setMenuToggle}
        rounded={true}
        color="#ccd6f6"
        duration={0.4}
      />
    </div>
  );

  const nav = (
    <div className="menu_mobile_nav">
      <div className="menu_mobile_nav_elements">
        <div
          style={currentIndex === 1 ? { color: "#64ffda" } : null}
          onClick={() => {
            sliderRef?.current?.swiper.slideTo(1);
            setMenuToggle(false);
          }}
        >
          About
        </div>
        <div
          style={currentIndex === 2 ? { color: "#64ffda" } : null}
          onClick={() => {
            sliderRef?.current?.swiper.slideTo(2);
            setMenuToggle(false);
          }}
        >
          Work
        </div>
        <div
          style={currentIndex === 12 ? { color: "#64ffda" } : null}
          onClick={() => {
            sliderRef?.current?.swiper.slideTo(12);
            setMenuToggle(false);
          }}
        >
          Contact
        </div>
      </div>

      <div className="menu_mobile_nav_icons">
        <div>
          <a
            href="https://github.com/Amin-Essid"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <StaticImage
              src="../../images/github.png"
              alt="github icon"
              width={37.32}
              height={40}
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/mohamed-amine-essid-868335143/"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <StaticImage
              src="../../images/linkedin.png"
              alt="linkedin icon"
              width={40}
              height={40}
            />
          </a>
        </div>
        <div>
          <a
            href="mailto:ameenessid@gmail.com"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <StaticImage
              src="../../images/email.png"
              alt="email icon"
              width={39.64}
              height={40}
            />
          </a>
        </div>
      </div>
    </div>
  );

  const mobileLogo = (
    <div
      onClick={() => {
        sliderRef?.current?.swiper.slideTo(0);
        setMenuToggle(false);
      }}
      className="mobile_logo"
    >
      <StaticImage
        alt="logo"
        src="../../images/mobilelogo.png"
        width={48}
        height={33.5}
      />
    </div>
  );

  return (
    <div>
      {menu}
      {mobileLogo}
      {menuToggle && nav}
    </div>
  );
};

export default MobileNav;
