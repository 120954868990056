import * as React from "react";
import Navbar from "../components/navbar/navbar";
import VerticalSwiper from "../components/verticalSwiper/verticalSwiper";
import "../styles/index.css";
import MobileNav from "../components/mobileNav/mobileNav";
import { useState, useRef } from "react";
import { Helmet } from "react-helmet";

// markup
const IndexPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef();
  return (
    <>
      <Helmet>
        <title>Amine Essid Portfolio</title>
        <meta
          name="description"
          content="I'm a freelancer web developer, specializing in building modern
    web applications for clients and businesses from all around the
    world"
        />
        <meta name="og:title" content="Amine Essid Portfolio" />
        <meta
          name="og:description"
          content="I'm a freelancer web developer, specializing in building modern
    web applications for clients and businesses from all around the
    world"
        />
        <meta name="og:image" content="/real_me.png" />
        <meta name="og:url" content="https://aminessid.com" />

        <meta name="image" content="/real_me.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Amine Essid Portfolio" />
        <meta name="twitter:url" content="https://aminessid.com" />
        <meta
          name="twitter:description"
          content="I'm a freelancer web developer, specializing in building modern
    web applications for clients and businesses from all around the
    world"
        />
        <meta name="twitter:image" content="/real_me.png" />
      </Helmet>
      <div className="home_container">
        <Navbar
          setCurrentIndex={setCurrentIndex}
          sliderRef={sliderRef}
          currentIndex={currentIndex}
        />
        <MobileNav
          setCurrentIndex={setCurrentIndex}
          sliderRef={sliderRef}
          currentIndex={currentIndex}
        />
        <VerticalSwiper
          setCurrentIndex={setCurrentIndex}
          sliderRef={sliderRef}
        />
      </div>
    </>
  );
};

export default IndexPage;
