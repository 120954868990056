import * as React from "react";
import "./project.css";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import SwiperCore, {
  Pagination,
  Navigation,
  Mousewheel,
  HashNavigation,
  History,
  Lazy,
} from "swiper";

SwiperCore.use([
  Pagination,
  Mousewheel,
  HashNavigation,
  History,
  Lazy,
  Navigation,
]);

const Project = ({ project, imagesUrls }) => {
  const imageRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = project.images.map((image, i) => {
    let img = imagesUrls.filter((url) => {
      return url.name === image.src;
    });
    return (
      <SwiperSlide key={i} className="project_img_slide">
        <GatsbyImage
          image={img[0].childImageSharp.gatsbyImageData}
          alt={project.title}
          width={400}
          height={400}
        />
      </SwiperSlide>
    );
  });

  // const infos = project.images.map((image, i) => {
  //   const texts = image.texts.map((text, j) => <p key={j}>{text}</p>);
  //   return <div key={i}>{texts}</div>;
  // });

  const texts = project.texts.map((text, j) => <p key={j}>{text}</p>);
  const techs = project.techs.map((tech, i) => <span key={i}>{tech}</span>);
  return (
    <div className="project_container">
      <div
        className={
          project.type === "reverse"
            ? "inner_project_container_reverse"
            : "inner_project_container"
        }
      >
        <div className="project_details">
          <div
            className={
              project.type === "reverse" ? "project_img_reverse" : "project_img"
            }
          >
            <Swiper
              className="project_img_swiper"
              ref={imageRef}
              loop={false}
              pagination={true}
              navigation={true}
              lazy={{
                loadPrevNext: true,
              }}
              onSlideChange={async (s) => {
                console.log(s.activeIndex);
                await setCurrentSlide(s.activeIndex);
              }}
              speed={500}
            >
              {images}
            </Swiper>
          </div>
          <div
            className={
              project.type === "reverse"
                ? "project_techs_reverse"
                : "project_techs"
            }
          >
            {techs}
          </div>
          {project.link && project.link !== "" && (
            <a
              href={project.link}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div
                className={
                  project.type === "reverse"
                    ? "project_urlbtn_reverse"
                    : "project_urlbtn"
                }
              >
                {project.linkTitle}
              </div>
            </a>
          )}
          {project.linkTwo && project.linkTwoTitle !== "" && (
            <a
              href={project.linkTwo}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div
                className={
                  project.type === "reverse"
                    ? "project_urlbtn_reverse"
                    : "project_urlbtn"
                }
              >
                {project.linkTwoTitle}
              </div>
            </a>
          )}
        </div>
        <div className="featuredProject_info">
          <div
            className={
              project.type === "reverse"
                ? "project_info_title_reverse"
                : "project_info_title"
            }
          >
            {project.title}
          </div>
          <div
            className={
              project.type === "reverse"
                ? "project_info_description_reverse"
                : "project_info_description"
            }
          >
            {texts}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
