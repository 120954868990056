import * as React from "react";
import "./navbar.css";
import { StaticImage } from "gatsby-plugin-image";

// markup
const Navbar = ({ setCurrentIndex, sliderRef, currentIndex }) => {
  return (
    <>
      <div className="navbar_container">
        <div
          onClick={() => sliderRef?.current?.swiper.slideTo(0)}
          className="navbar_logo_container"
        >
          <StaticImage
            alt="logo"
            src="../../images/logo.png"
            width={98}
            height={85}
          />
        </div>
        <div className="navbar_nav_container">
          <div
            style={currentIndex === 1 ? { color: "#64ffda" } : null}
            onClick={() => sliderRef?.current?.swiper.slideTo(1)}
          >
            About
          </div>
          <div
            style={currentIndex === 2 ? { color: "#64ffda" } : null}
            onClick={() => sliderRef?.current?.swiper.slideTo(2)}
          >
            Work
          </div>
          <div
            style={currentIndex === 12 ? { color: "#64ffda" } : null}
            onClick={() => sliderRef?.current?.swiper.slideTo(12)}
          >
            Contact
          </div>
        </div>
        <div className="navbar_icons_container">
          <div>
            <a
              href="https://github.com/Amin-Essid"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <StaticImage
                src="../../images/github.png"
                alt="github icon"
                width={18.66}
                height={20}
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/in/mohamed-amine-essid-868335143/"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <StaticImage
                src="../../images/linkedin.png"
                alt="linkedin icon"
                width={20}
                height={20}
              />
            </a>
          </div>
          <div>
            <a
              href="mailto:ameenessid@gmail.com"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <StaticImage
                src="../../images/email.png"
                alt="email icon"
                width={19.85}
                height={20}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
