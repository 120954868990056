import * as React from "react";
import "./about.css";
import { StaticImage } from "gatsby-plugin-image";
const About = () => {
  return (
    <div className="about_container">
      <div className="about_container_inner">
        <div className="about_img">
          <StaticImage
            alt="my second image"
            src="../../images/standing_me.jpg"
          />
        </div>
        <div className="about_text">
          <div className="about_title">About Me</div>
          <div className="about_details">
            <p>
              Hello! My name is Ameen and I am a passionate web developer
              located in Tunisia. I've been freelancing for 4 years developing
              modern web applications and working on solving the most
              challenging problems for clients and businesses from all around
              the world.
            </p>
            <p>
              I am a full-stack javascript / typescript developer, and my
              favourite frameworks are React.js, Next.js, Node.js and Firebase.
            </p>
            <p>
              I am a well-organized person with high attention to details, a
              problem solver, and a great communicator.
            </p>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default About;
