import * as React from "react";
import "./verticalSwiper.css";
import About from "../about/about";
import Header from "../header/header";
import Contact from "../contact/contact";
import { Swiper, SwiperSlide } from "swiper/react";
import FeaturedProject from "../featuredProject/featuredProject";
import { useStaticQuery, graphql } from "gatsby";
import Project from "../project/project";

import "swiper/css";
import "swiper/css/pagination";

import SwiperCore, {
  Pagination,
  Mousewheel,
  HashNavigation,
  History,
  Lazy,
  Keyboard,
} from "swiper";

SwiperCore.use([
  Pagination,
  Mousewheel,
  HashNavigation,
  History,
  Lazy,
  Keyboard,
]);

// markup
const VerticalSwiper = ({ setCurrentIndex, sliderRef }) => {
  const data = useStaticQuery(graphql`
    {
      wildcampersData: allFile(
        filter: { relativeDirectory: { eq: "wildcampers" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      vendlyData: allFile(filter: { relativeDirectory: { eq: "vendly" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      improvementData: allFile(
        filter: { relativeDirectory: { eq: "improvement" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      fiverrData: allFile(filter: { relativeDirectory: { eq: "fiverr" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      btounsiData: allFile(filter: { relativeDirectory: { eq: "btounsi" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      gazData: allFile(filter: { relativeDirectory: { eq: "gaz" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      testGorillaData: allFile(
        filter: { relativeDirectory: { eq: "testGorilla" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      brownandkayData: allFile(
        filter: { relativeDirectory: { eq: "brownandkay" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      make3dData: allFile(filter: { relativeDirectory: { eq: "make3d" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      mizarData: allFile(filter: { relativeDirectory: { eq: "mizar" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      maData: allFile(filter: { relativeDirectory: { eq: "ma" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      gaeloData: allFile(filter: { relativeDirectory: { eq: "gaelo" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      vitanasData: allFile(filter: { relativeDirectory: { eq: "vitanas" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      notarypageData: allFile(
        filter: { relativeDirectory: { eq: "notarypage" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
      ziawayData: allFile(filter: { relativeDirectory: { eq: "ziaway" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              width: 800
              quality: 90
              placeholder: BLURRED
              formats: [AUTO]
            )
          }
        }
      }
    }
  `);

  const wcimagesUrls = data.wildcampersData.nodes;

  const vendlyimagesUrls = data.vendlyData.nodes;

  const improvementimagesUrls = data.improvementData.nodes;

  const btounsiimagesUrls = data.btounsiData.nodes;

  const gazimagesUrls = data.gazData.nodes;

  const testGorillaimagesUrls = data.testGorillaData.nodes;

  const brownandkayimagesUrls = data.brownandkayData.nodes;

  const ziawayImagesUrls = data.ziawayData.nodes;

  const make3dUrls = data.make3dData.nodes;

  const mizarUrls = data.mizarData.nodes;

  const maUrls = data.maData.nodes;

  const gaeloUrls = data.gaeloData.nodes;

  const vitanasUrls = data.vitanasData.nodes;

  const notarypageUrls = data.notarypageData.nodes;

  const vendly = {
    title: "VENDLY",
    link: "https://vendly.com/",
    linkTitle: "visit website",
    type: "reverse",
    texts: [
      "Vendly is an African platform that allows users to send secure packages (payments, degrees, gifts…) through social media. ",
      "I worked on the backend for this startup and built most of it using Firebase and node js.",
      "In addition, I helped developing their frontend web application.",
    ],
    images: [
      {
        src: "home",
      },
    ],
    techs: ["React.js", "Next.js", "Firebase", "Node js"],
  };

  const testGorilla = {
    title: "Gatsby.js test on testGorilla",
    link: "https://www.testgorilla.com/test-library/programming-skills-tests/gatsby-js-test/",
    linkTitle: "visit website",
    type: "reverse",
    texts: [
      "I've got hired by TestGorilla to create a Gatsby.js test that evaluates candidates’ knowledge of the Gatsby framework and their ability to create websites using this technology. This test helps recruiters identify developers who can generate fast and responsive static sites.",
      "This test has 104 situational questions that cover all the skills area related to Gatsby.",
    ],
    images: [
      {
        src: "me",
      },
      {
        src: "main",
      },
      {
        src: "test_one",
      },
      {
        src: "test_two",
      },
    ],
    techs: ["React.js", "Gatsby.js", "javascript"],
  };

  const gaz = {
    title: "GCCI.Earth",
    link: "https://633bdf8c58657b406a2d0068--gcci.netlify.app/",
    linkTwo: "https://gcci.earth/",
    linkTwoTitle: "visit old version",
    linkTitle: "visit ongoing version",
    type: "reverse",
    texts: [
      "This website is a data portal that is powered by the Wegener Center of the University of Graz on behalf of its Field of Excellence Climate Change Graz.",
      "I am working on adding more content and features on a regular basis to this project (you will find the URL of the ongoing work and features below, and after each milestone all the new features are deployed to real gcci.earth website).",
      "The charts are generally made to be self-explanatory - for a one-stop overview of the current content, including detailed data-source references.",
    ],
    images: [
      {
        src: "one",
      },
      {
        src: "two",
      },
      {
        src: "three",
      },
      {
        src: "four",
      },
    ],
    techs: ["React.js", "Gatsby.js", "D3.js", "Typescript"],
  };

  const brownandkay = {
    title: "BROWN & KAY",
    link: "https://brownandkay.co.uk/",
    linkTitle: "visit website",
    type: "normal",
    texts: [
      "Brown & kay is specialising in residential sales & lettings in the UK. they sell all styles of property from studio flats and retirement apartments through to luxury residential homes.",
      "I worked on their website adding new features and fixing bugs.",
    ],
    images: [
      {
        src: "one",
      },
      {
        src: "two",
      },
      {
        src: "three",
      },
      {
        src: "four",
      },
      {
        src: "five",
      },
    ],
    techs: ["React.js", "Gatsby.js", "Javascript"],
  };

  const make3d = {
    title: "MAKE3D.BIZ",
    link: "https://make3d.biz/",
    linkTitle: "visit website",
    type: "normal",
    texts: [
      "MAKE3D.BIZ sells 3D printers in Europe; I worked on their website to optimize the performance and SEO of their web application.",
      "In addition, I created new Gatsby.js pages that allows the owners to generate new handbooks and guides for each new printer using Strapi CMS.",
    ],
    images: [
      {
        src: "home",
      },
      {
        src: "handbook",
      },
      {
        src: "category",
      },
      {
        src: "guide",
      },
      {
        src: "step",
      },
    ],
    techs: ["React.js", "Next.js", "Firebase", "Node js"],
  };

  const wildcampers = {
    title: "WILDCAMPERS",
    type: "normal",
    link: "",
    linkTitle: "visit website",
    texts: [
      "Wildcampers is a Spanish company that allows customers to rent camper vans.",
      "However, after the challenges of the corona virus, wildcampers decided to start selling these vehicles too and my job was to build a fast website with a good SEO to help them on that new business.",
    ],
    images: [
      {
        src: "home",
      },
      {
        src: "filter",
      },
      {
        src: "form",
      },
      {
        src: "vans",
      },
    ],
    techs: ["React.js", "Next.js", "material ui", "Firebase"],
  };

  const improvement = {
    title: "IMPROVEMENT",
    type: "reverse",
    link: "https://suggestion-improvement.vercel.app/",
    linkTitle: "visit demo website",
    texts: [
      "A German company needed a website for their employees to give suggestions to improve the company workflow and culture. ",
      "I built a platform for them that allows employees to create an account, create a suggestion (and edit it or even delete it), add images to their posts to explain it more, and they can upvote the suggestions of other employees too. ",
    ],
    images: [
      {
        src: "home",
      },
      {
        src: "create_post",
      },
      {
        src: "post_comment",
      },
      {
        src: "sign_up",
      },
    ],
    techs: ["Next.js", "React.js", "material ui", "Firebase"],
  };

  const btounsi = {
    title: "BTOUNSI",
    type: "reverse",
    link: "",
    linkTitle: "visit demo website",
    texts: [
      "Btounsi is a single web application that interacts with Youtube API to get Tunisian TV shows from each original owner's channel. Any user can filter shows by channel, year, or type to find his best show.",
    ],
    images: [
      {
        src: "home",
      },
      {
        src: "filter",
      },
      {
        src: "show",
      },
      {
        src: "episode",
      },
    ],
    techs: ["Create-react-app", "React.js", "Youtube API"],
  };

  const ma = {
    title: "Createdby.ma",
    link: "https://createdby.ma/",
    linkTitle: "visit website",
    type: "normal",
    texts: [
      "MA specializes in visualizing unbuilt masterplanned and hospitality projects, and the odd residential and commercial building.",
      "I built their entire website to show their previous work and get new clients. All the website content is dynamic, and they can update it or add new projects and section from the content management system (CMS). ",
      "The website has a real time chat that appears only when the admin login to the admin dashboard.",
    ],
    images: [
      {
        src: "home",
      },
      {
        src: "chat",
      },
      {
        src: "admin",
      },
      {
        src: "strapi",
      },
      {
        src: "services",
      },
      {
        src: "map",
      },
      {
        src: "360",
      },
    ],
    techs: ["React.js", "Next.js", "Strapi", "Firebase"],
  };

  const vitanas = {
    title: "Web Developer Consultant at VITANAS24",
    link: "https://vitanas.pl/",
    linkTitle: "visit website",
    type: "reverse",
    texts: [
      "I served as a Front-end Developer consultant at VITANAS24 LTD, Sp. K., collaborating with a skilled team to develop an innovative product facilitating the connection between nurses, caregivers, and individuals in need of assistance, particularly focusing on patients and the elderly.",
    ],
    images: [
      {
        src: "one",
      },
    ],
    techs: ["React.js", "Next.js", "Typescript", "Material UI", "Jira", "Git"],
  };

  const gaelo = {
    title: "Gaelo",
    link: "https://www.gaelo.fr/",
    linkTitle: "visit website",
    type: "reverse",
    texts: [
      "I created this website to showcase GaelO's products, services, and team. GaelO is a full web end-to-end medical imaging platform for clinical trial. GaelO allows a smooth collection and management of images from recruiting centers to centralized medical review with efficient and adapted tools for each actor of clinical research.",
    ],
    images: [
      {
        src: "one",
      },
    ],
    techs: ["React.js", "Gatsby.js", "GraphQL", "Bootstrap"],
  };

  const mizar = {
    title: "Web Developer Consultant at Mizar",
    link: "https://mizar.com/blog",
    linkTwo: "https://mizar.com/",
    linkTwoTitle: "mizar.com",
    linkTitle: "mizar.com/blog",
    type: "normal",
    texts: [
      "As a Web Development Consultant at Mizar.com, I constructed their blog using Next.js, Tailwind, and Contentful. Additionally, I enhanced various pages on their website by creating new components and optimizing SEO for improved performance.",
    ],
    images: [
      {
        src: "one",
      },
      {
        src: "two",
      },
      {
        src: "three",
      },
      {
        src: "four",
      },
      {
        src: "five",
      },
      {
        src: "six",
      },
    ],
    techs: ["React.js", "Next.js", "Tailwind", "Contentful"],
  };

  const notarypage = {
    title: "Notary Page",
    type: "normal",
    texts: [
      "I developed the Minimum Viable Product (MVP) for Notary Page, a platform designed to connect notaries with potential clients in the United States. Through this platform, notaries can showcase their services, and users across the USA can easily find and engage with the best notary professionals based on location, either by city or zip code. Additionally, users have the flexibility to filter their search based on specific service types, ensuring a tailored and efficient experience.",
    ],
    images: [
      {
        src: "one",
      },
      {
        src: "two",
      },
      {
        src: "three",
      },
      {
        src: "four",
      },
      {
        src: "five",
      },
      {
        src: "six",
      },
    ],
    techs: [
      "React.js",
      "Next.js",
      "Firebase",
      "Algolia",
      "TailwindCSS",
      "Stripe API",
      "Node.js",
    ],
  };

  const ziaway = {
    title: "Ziaway",
    link: "https://ziaway.com/",
    linkTitle: "visit website",
    type: "normal",
    texts: [
      "Ziaway, a Canadian marketplace, optimizes the connection between individuals and real estate professionals. As a Full Stack developer with Ziaway's team, I leverage React.js, Node.js, and Firebase to redesign the platform and enhance its capabilities.",
    ],
    images: [
      {
        src: "one",
      },
    ],
    techs: [
      "React.js",
      "Redux",
      "Firebase",
      "Bootstrap",
      "Stripe API",
      "GitHub",
      "Node.js",
    ],
  };

  return (
    <>
      <div className="verticalSwiper_container">
        <Swiper
          direction="vertical"
          className="mySwiper"
          ref={sliderRef}
          loop={false}
          showspagination={{ enabled: false }}
          //   preloadImages={false}
          lazy={{
            loadPrevNext: true,
          }}
          mousewheel={{ enabled: true }}
          //   hashNavigation={{ watchState: true, replaceState: true }}
          speed={500}
          keyboard={{
            enabled: true,
            onlyInViewport: false,
          }}
          onSlideChange={async (s) => {
            setCurrentIndex(s.activeIndex);
          }}
        >
          <SwiperSlide className="verticalSwiper_slide">
            <Header />
          </SwiperSlide>
          <SwiperSlide className="verticalSwiper_slide">
            <About />
          </SwiperSlide>
          <SwiperSlide className="verticalSwiper_slide">
            <FeaturedProject project={ziaway} imagesUrls={ziawayImagesUrls} />
          </SwiperSlide>
          <SwiperSlide className="verticalSwiper_slide">
            <Project project={vitanas} imagesUrls={vitanasUrls} />
          </SwiperSlide>
          <SwiperSlide className="verticalSwiper_slide">
            <Project project={mizar} imagesUrls={mizarUrls} />
          </SwiperSlide>
          <SwiperSlide className="verticalSwiper_slide">
            <Project project={gaelo} imagesUrls={gaeloUrls} />
          </SwiperSlide>
          <SwiperSlide className="verticalSwiper_slide">
            <Project project={notarypage} imagesUrls={notarypageUrls} />
          </SwiperSlide>
          <SwiperSlide className="verticalSwiper_slide">
            <Project project={gaz} imagesUrls={gazimagesUrls} />
          </SwiperSlide>
          <SwiperSlide className="verticalSwiper_slide">
            <Project project={brownandkay} imagesUrls={brownandkayimagesUrls} />
          </SwiperSlide>
          <SwiperSlide className="verticalSwiper_slide">
            <Project project={testGorilla} imagesUrls={testGorillaimagesUrls} />
          </SwiperSlide>
          {/* <SwiperSlide className="verticalSwiper_slide">
            <Project project={ma} imagesUrls={maUrls} />
          </SwiperSlide> */}
          {/* <SwiperSlide className="verticalSwiper_slide">
            <Project project={make3d} imagesUrls={make3dUrls} />
          </SwiperSlide> */}
          {/* <SwiperSlide className="verticalSwiper_slide">
            <Project project={vendly} imagesUrls={vendlyimagesUrls} />
          </SwiperSlide> */}
          {/* <SwiperSlide className="verticalSwiper_slide">
            <Project project={improvement} imagesUrls={improvementimagesUrls} />
          </SwiperSlide> */}
          {/* <SwiperSlide className="verticalSwiper_slide">
            <Project project={wildcampers} imagesUrls={wcimagesUrls} />
          </SwiperSlide> */}
          {/* <SwiperSlide className="verticalSwiper_slide">
            <Project project={btounsi} imagesUrls={btounsiimagesUrls} />
          </SwiperSlide> */}
          <SwiperSlide className="verticalSwiper_slide">
            <Contact />
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default VerticalSwiper;
